<template>
  <div class="app-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">车辆信息</span>
        </el-col>
      </el-row>
      <div ref="searchBar" class="form-cls">
        <el-form @heightChange="_getTableHeight()" size="small">
          <el-row>
            <el-col :span="6">
              <el-form-item label="车牌号码:">
                <el-input
                  v-model.trim="queryParams.carPlate"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运输单位:">
                <el-input
                  v-model.trim="queryParams.fleetName"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询 </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- </search-bar> -->
      </div>
      <div class="main-body">
        <vxe-toolbar ref="xToolbar" custom style="margin-left: 10px; padding-right: 10px">
          <template #buttons>
            <el-upload
              :action="`${uploadUrl}?type=0`"
              :data="uploadData"
              :headers="uploadHeaders"
              :on-success="uploadFileSuccess"
              :on-error="uploadFileError"
              :show-file-list="false"
              :file-list="[]"
            >
              <el-button plain key="addFile" size="small" type="primary"> 新增导入 </el-button>
            </el-upload>

            <el-upload
              style="margin-left: 10px"
              :data="uploadData"
              :headers="uploadHeaders"
              :on-success="uploadFileSuccess"
              :on-error="uploadFileError"
              :action="`${uploadUrl}?type=1`"
              :show-file-list="false"
              :file-list="[]"
            >
              <el-button plain key="addUpdate" size="small" type="primary"> 导入更新 </el-button>
            </el-upload>
            <el-button plain style="margin-left: 10px" key="addFile1" size="small" @click="exportFileTemp" type="primary">下载导入模板</el-button>
            <el-button plain   style="margin-left: 10px" key="addFile" size="small" @click="exportFile" type="primary"> 导出 </el-button>
          </template>
          <template #tools>
            <vxe-pager
              align="left"
              placement="top"
              :total="total"
              :current-page.sync="queryParams.currentPage"
              border
              :page-sizes="[100, 120, 150]"
              size="small"
              :page-size.sync="queryParams.pageSize"
              :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
              @page-change="queryList('0')"
            />
          </template>
        </vxe-toolbar>
      </div>
      <el-row class="form-cls button-cls">
        <el-col :span="24">
          <vxe-table
            id="dataListTable"
            ref="table"
            v-loading="listLoading"
            class="sortable-column-demo"
            :export-config="{}"
            :data="dataList"
            fit
            :height="_tableHeight"
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            :resizable-config="{ minWidth: 50 }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            show-overflow="tooltip"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <vxe-table-column type="seq" title="序号" width="150" align="center" />
            <vxe-table-column field="carNumber" title="车牌号码" align="center" />
            <vxe-table-column field="fleetName" title="运输单位" align="center" />
            <vxe-table-column field="carModel" title="车辆类型" align="center" />
            <vxe-table-column field="carNumberColor" title="车牌颜色" align="center" />
            <vxe-table-column field="maxWeight" title="总质量" align="center" />
            <vxe-table-column field="loadWeight" title="核定载重量" align="center" />
            <vxe-table-column field="canWeight" title="可装载吨位" align="center" />
          </vxe-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import CalTableHeight from '@/mixins/calTableHeight'
import { baseCarList as queryList, baseCarListExport as queryListExport, baseCarDownloadTemplate as downloadTemplate } from '@/api/base'
import { getToken } from '@/utils/auth.js'
import downloadFile  from '@/utils/downloadFile'
export default {
  data() {
    return {
      total: 0,
      uploadUrl: '/admin-gov/car/upload', // admin-gov
      uploadData: {},
      uploadHeaders: {
        Authorization: `Bearer ${getToken()}` // 让每个请求携带自定义token 请根据实际情况自行修改
      },
      queryParams: {
        carPlate: '',
        fleetName: '',
        currentPage: 1,
        pageSize: 100
      },
      dataList: [],
      listLoading: false
    }
  },
  mixins: [CalTableHeight],
  computed: {},
  async mounted() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联,否则列配置将失效
      this.$refs.table.connect(this.$refs.xToolbar)
    })
    this.$nextTick(() => {
      this._getTableHeight()
    })
    this.queryList()
  },
  methods: {
    exportFile() {
      const { queryParams } = this
      queryListExport({
        ...queryParams
      }).then(res => {
        downloadFile(res)
      })
    },
    exportFileTemp(){
      return downloadTemplate({
      }).then(res=>{
        downloadFile(res)
      })
    },
    uploadFileSuccess() {
      this.$message.success(`上传成功`)
    },
    uploadFileError(err) {
      const res = JSON.parse(err.message)
      this.$message.error( res.message || `上传失败`)
    },
    queryList() {
      this.listLoading = true
      return queryList(this.queryParams)
        .then(({ data }) => {
          this.dataList = data.records
          this.total = data.total
        })
        .finally(() => {
          this.listLoading = false
        })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}

.icon-cls-green {
  color: #67c23a;
}

.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.amap-section {
  height: 600px;
}

.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}

.el-pagination {
  float: right;
}

.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
  background-color: #dfecfb;
}

.vxe-table--footer .col-blue {
  background: #f2f5f0;
}

/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}

.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}

.write-color {
  border-color: #f0f0f0;
}

/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}

.input-width-7 {
  width: 70% !important;
}

.input-width-8 {
  width: 80% !important;
}

.input-width-16 {
  width: 300px;
}
</style>
